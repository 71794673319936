// cSpell: disable
import axios from 'axios'

const brazilianStates = {
  AC: 'Acre',
  AL: 'Alagoas',
  AM: 'Amazonas',
  AP: 'Amapá',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MG: 'Minas Gerais',
  MS: 'Mato Grosso do Sul',
  MT: 'Mato Grosso',
  PA: 'Pará',
  PB: 'Paraíba',
  PE: 'Pernambuco',
  PI: 'Piauí',
  PR: 'Paraná',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RO: 'Rondônia',
  RR: 'Roraima',
  RS: 'Rio Grande do Sul',
  SC: 'Santa Catarina',
  SE: 'Sergipe',
  SP: 'São Paulo',
  TO: 'Tocantins',
}

const accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmNjRmYmM4MS0zOTY4LTQ3YjYtODkyZC04NWU0MDQ3MTVkNzAiLCJvcmciOiJvcmcwMDAxMjEifQ.VEDLqbRglJY-MWcwlOVCjO2BHtwxG9rYzUwz1z4EB4M'

const config = {
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
}

const requests = {
  headers: [
    { header: 'Referência no Ofício', key: 'reference' },
    { header: 'Data do pagamento/ recebimento', key: 'dataemissao' },
    { header: 'Número da operação', key: 'numeroserie' },
    { header: 'Chave de acesso da Nota Fiscal eletrônica', key: 'chaveacesso' },
    { header: 'Ambiente de negociação', key: 'ambient' },
    { header: 'Tipo de operação', key: 'operationType' },
    { header: 'Data da operação', key: 'dataemissao' },
    { header: 'Forma de pagamento', key: '' },
    { header: 'Descrição dos bens/mercadorias', key: 'descricaoveiculomodelo' },
    { header: 'Valor da operação em R$', key: 'valor' },
    { header: 'Espécie', key: 'valordinheiro' },
    { header: 'Transferências bancárias', key: '' },
    { header: 'Depósito em espécie em conta', key: '' },
    { header: 'Cartões de pagamento', key: 'valorcartao' },
    { header: 'Cheques', key: 'valorcheque' },
    { header: 'Boletos bancários', key: '' },
    { header: 'Ativos virtuais', key: '' },
    { header: 'Remessa internacional', key: '' },
    { header: 'Financiamento', key: '' },
    { header: 'Bens', key: '' },
    { header: 'Desconto', key: 'desconto' },
    { header: 'Pagamento realizado por terceiros?', key: '' },
    { header: 'Valor do pagamento por terceiro', key: '' },
    { header: 'Nome/nome empresarial do pagador/recebedor', key: '' },
    { header: 'CPF/CNPJ do recebedor/pagador', key: '' },
    { header: 'Nome ou nome empresarial do vendedor', key: 'vendedor' },
    { header: 'CPF/CNPJ do vendedor', key: 'cpf_vendedor' },
    { header: 'Tipo de cliente', key: 'clientType' },
    { header: 'Nome ou nome empresarial', key: 'pessoa' },
    { header: 'CPF/CNPJ do cliente', key: 'cpfcnpj' },
    { header: 'Número do documento de identificação do cliente (se cliente pessoa física)', key: 'rgouinscricaoestadual' },
    { header: 'Órgão expedidor do documento de identificação do cliente (se cliente pessoa física)', key: '' },
    { header: 'Unidade da Federação (UF) em que o documento do cliente foi emitido no Brasil (se cliente pessoa física)', key: 'ufrg' },
    { header: 'Logradouro', key: 'address' },
    { header: 'CEP', key: 'cep' },
    { header: 'Município', key: 'empresa' },
    { header: 'UF', key: 'uf' },
    { header: 'Cliente é PEP?\n(se cliente pessoa física)', key: 'pepClient' },
    { header: 'Nome do preposto', key: '' },
    { header: 'CPF do preposto', key: '' },
    { header: 'Número do documento de identificação do preposto', key: '' },
    { header: 'Órgão expedidor do documento no preposto', key: '' },
    { header: 'Unidade da federação (UF) em que o documento do preposto foi emitido no Brasil.', key: '' },
    { header: 'Preposto é PEP?', key: '' },
  ],

  /**
   * Retrieves COAF data for the given dates, selected companies, and cash value.
   *
   * @param {string[]} dates - The dates to filter the data.
   * @param {number[]} selectedCompanies - The list of selected companies.
   * @param {string} cashValue - The cash value to filter the data.
   * @return {Promise} A promise that resolves with the COAF data.
   */
  async getCoafData(dates, selectedCompanies, cashValue) {
    const [firstDate, lastDate] = dates
    const body = {
      idrelatorioconfiguracao: 233,
      idrelatorioconsulta: 114,
      idrelatorioconfiguracaoleiaute: 233,
      idrelatoriousuarioleiaute: 1542,
      ididioma: 1,
      listaempresas: selectedCompanies,
      filtros: `Origem=9;
      Pessoa=null;
      RelacaoComercial=null;
      Datademovimentacaoinicial=${firstDate};
      Datademovimentacaofinal=${lastDate};
      PorSomaMovimentacao=False;
      LancamentoTipoSistema=6,40,1,44,45,46,48,7,47,41;
      ValorTotalDinheiro=${cashValue};
      Vendedor=null`,
    }

    const responseData = await axios.post('https://microworkcloud.com.br/api/integracao/terceiro', body, config).then(async response => {
      const { data } = response

      return this.getDataFromPeople(data, dates, selectedCompanies)
    }).catch(error => {
      console.error(error)
    })

    const sortedByValue = this.sortByValue(responseData)
    const sortedByDate = this.sortByDate(sortedByValue)
    const formattedData = this.formatDataToExport(sortedByDate)

    return formattedData
  },

  async getDataFromPeople(data, dates, selectedCompanies) {
    const [firstDate, lastDate] = dates

    const body = {
      idrelatorioconfiguracao: 238,
      idrelatorioconsulta: 117,
      idrelatorioconfiguracaoleiaute: 238,
      idrelatoriousuarioleiaute: 1543,
      ididioma: 1,
      listaempresas: selectedCompanies,
      filtros: `TipoMovimento=null;
      TipoItem=3;
      TipoMercadoria=null;
      SomenteNFComum=False;
      Departamento=null;
      Pessoa=${this.extractCodeFromPerson(data)};
      Receita=null;
      IncluirNFComum=False;
      Periododeemissaoinicial=${firstDate};
      Periododeemissaofinal=${lastDate}`,
    }

    const newData = await axios.post('https://microworkcloud.com.br/api/integracao/terceiro', body, config).then(response => {
      const { data: apiData } = response
      const extractCode = text => (text.split(' ')[1])

      const temp = data.map(element => {
        const matchingData = apiData.filter(item => extractCode(item.numeroserie) === element.notafiscal)
        const { descricaoveiculomodelo, chaveacesso } = matchingData[0] || {}

        return {
          ...element, ...(matchingData[0] || {}), descricaoveiculomodelo, chaveacesso,
        }
      })

      return temp
    }).catch(error => {
      console.error(error)
    })

    return newData
  },

  extractCodeFromPerson(data) {
    const regex = /\((\d+)\)$/
    const usersCode = data.map(({ pessoa }) => {
      const match = pessoa.match(regex)

      return match[1]
    })

    return usersCode
  },

  sortByValue(data) {
    if (!data || data.length === 0) {
      return data
    }

    const formattedData = data.filter(element => element.descricaofinanceirolancamentotiposistema === 'RECEBIMENTO DE CONTAS')
    formattedData.sort((a, b) => b.valordinheiro - a.valordinheiro)

    return formattedData
  },

  sortByDate(data) {
    if (!data) return data

    const formattedData = data.sort((a, b) => {
      const dateA = new Date(a.dataemissao)
      const dateB = new Date(b.dataemissao)

      return dateA - dateB
    })

    return formattedData
  },

  formatDataToExport(data) {
    if (!data) return data

    const formattedData = data.map(element => {
      const {
        logradouro, bairro, complementoendereco, numero,
      } = element
      const isPhysicalPerson = (element?.descricaopessoatipoidioma === 'FÍSICA')

      const pepClient = isPhysicalPerson && element.pessoapoliticamenteexposta ? ' 1 (Sim)' : '2 (Não)'
      const ufrg = brazilianStates[element.ufrg]?.toUpperCase() ?? ''
      const dataemissao = this.dateFormat(element.dataemissao)
      const clientType = isPhysicalPerson ? ' 1 (Pessoa física)' : '2 (Pessoa jurídica)'
      const rgouinscricaoestadual = isPhysicalPerson ? element.rgouinscricaoestadual : ''
      const descricaoveiculomodelo = `${element.descricaoveiculomodelo}, CHASSI ${element.chassi}`
      const address = `${logradouro}, ${numero}, ${complementoendereco}, ${bairro}`

      return {
        ...element,
        ufrg,
        bairro,
        address,
        pepClient,
        clientType,
        dataemissao,
        uf: element.ufrg,
        rgouinscricaoestadual,
        descricaoveiculomodelo,
        operationType: '1 (Venda)',
        reference: 'OP.CLIENTE.ESP',
        ambient: '1 (estabelecimento físico)',
      }
    })

    return formattedData
  },

  dateFormat(date) {
    if (typeof date !== 'string' || !date) {
      return date
    }

    const dateSliced = date.slice(0, 10)
    const dateDivided = dateSliced.split('-')
    const dateFormated = `${dateDivided[2]}/${dateDivided[1]}/${dateDivided[0]}`

    return dateFormated
  },
}

export default requests

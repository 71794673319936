<template>
  <v-card>
    <v-card-title>
      <span>
        Relatório COAF
      </span>
    </v-card-title>

    <v-card-text>
      <v-form
        ref="form"
        class="container-filter"
        @submit.prevent
      >
        <v-autocomplete
          v-model="selectedCompanies"
          :items="companyList"
          item-text="name"
          item-value="id"
          label="Empresa"
          style="max-width: 460px;"
          :rules="[rules.requiredArray]"
          clearable
          outlined
          multiple
          dense
        />

        <v-dialog
          ref="dialogDate"
          v-model="showCalendar"
          :return-value.sync="dates"
          width="400px"
          persistent
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDate"
              :prepend-inner-icon="icons.mdiCalendar"
              :rules="[rules.requiredArrayDate]"
              label="Período"
              clearable
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            />
          </template>

          <v-date-picker
            v-model="dates"
            width="400px"
            locale="pt-BR"
            scrollable
            range
            @dblclick="handleMonthSelection()"
          >
            <v-spacer />

            <v-btn
              text
              color="primary"
              @click="showCalendar = false"
            >
              Cancelar
            </v-btn>

            <v-btn
              text
              color="primary"
              @click="$refs.dialogDate.save(dates)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

        <v-text-field
          v-model="cashValue"
          label="Valor total em dinheiro acima de"
          :rules="[rules.required]"
          outlined
          dense
        />

        <v-btn
          color="info"
          outlined
          @click="sendFormData()"
        >
          <v-icon v-if="!isLoading">
            {{ icons.mdiSendCircleOutline }}
          </v-icon>

          <v-progress-circular
            v-else
            color="white"
            indeterminate
          />
        </v-btn>

        <v-btn
          outlined
          @click="resetForm()"
        >
          <v-icon class="mr-2">
            {{ icons.mdiFilterRemoveOutline }}
          </v-icon>

          <span>Limpar</span>
        </v-btn>

        <v-btn
          color="warning"
          :disabled="exportButtonIsDisabled"
          outlined
          @click="exportButtonIsDisabled = !exportButtonIsDisabled"
        >
          <v-icon class="mr-2">
            {{ icons.mdiDownloadBox }}
          </v-icon>

          <span>Exportar</span>
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiCalendar, mdiDownloadBox,
  mdiFilterRemoveOutline,
  mdiSendCircleOutline,
} from '@mdi/js'
import { saveAs } from 'file-saver'
import requests from './requests'

const ExcelJs = require('exceljs')

export default {
  mixins: [formatters, messages],

  data() {
    return {
      companyList: [
        { id: 'all', name: 'Selecionar todas' },
        { id: 3, name: 'Imperatriz' },
        { id: 4, name: 'Açailândia' },
        { id: 5, name: 'Grajaú' },
        { id: 6, name: 'Itinga do Maranhão' },
        { id: 7, name: 'Porto Franco' },
        { id: 8, name: 'Cidelândia' },
        { id: 9, name: 'Amarante do Maranhão' },
        { id: 10, name: 'Arame' },
        { id: 11, name: 'Senador La Roque' },
        { id: 12, name: 'Sitio Novo' },
        { id: 13, name: 'Formosa da Serra Negra' },
        { id: 14, name: 'Bom Jesus das Selvas' },
        { id: 16, name: 'FUJI' },
      ],

      dates: [],
      cashValue: '',
      selectedCompanies: [],

      isLoading: false,
      showCalendar: false,
      exportButtonIsDisabled: true,

      rules: {
        requiredArray: value => value.length > 0 || 'Nenhuma empresa selecionado',
        requiredArrayDate: value => value.length > 1 || 'Selecione um período',
      },

      icons: {
        mdiCalendar,
        mdiDownloadBox,
        mdiSendCircleOutline,
        mdiFilterRemoveOutline,
      },
    }
  },

  computed: {
    computedDate: {
      get() {
        const sortedDates = this.sortDates(this.dates)
        const formattedDates = sortedDates.map(date => this.formatDate(date))

        return formattedDates
      },

      set() {
        this.dates = []
      },
    },
  },

  watch: {
    selectedCompanies(array) {
      if (array.includes('all')) {
        const filteredCompanies = this.companyList.filter(({ id }) => id !== 'all')
        this.selectedCompanies = filteredCompanies.map(({ id }) => id)
      }
    },
  },

  methods: {
    async sendFormData() {
      const { dates, selectedCompanies, cashValue } = this
      const isValid = this.$refs.form.validate()

      if (!isValid) {
        this.showMessage({ title: 'Invalido!', text: 'por favor preencher todos os campos', icon: 'error' })

        return
      }

      try {
        this.isLoading = true

        const data = await requests.getCoafData(dates, selectedCompanies, cashValue)
        const file = await this.createFile(data)
        saveAs(file)
      } catch (error) {
        console.log(error.message)
        this.showErrorMessage(error)
      } finally {
        this.isLoading = false
      }
    },

    async createFile(data) {
      const colIndexMap = {}
      const { headers } = requests

      const workbook = new ExcelJs.Workbook()
      const worksheet = workbook.addWorksheet('Relatório')
      worksheet.getRow(1).font = { bold: true }
      worksheet.getRow(1).values = headers.map(({ header }, index) => {
        colIndexMap[header] = index + 1

        return header
      })

      try {
        data.forEach((item, index) => {
          const rowIndex = index + 2
          const row = worksheet.getRow(rowIndex)

          headers.forEach(header => {
            const colIndex = colIndexMap[header.header]
            const value = item[header.key] || ''

            row.getCell(colIndex).value = value
          })
        })
      } catch (error) {
        console.log(error)
      }

      let blob = null
      await workbook.xlsx.writeBuffer().then(element => {
        blob = new File([element], 'conferencia-folha', {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      })

      return blob
    },

    handleMonthSelection() {
      const { $refs, dates } = this

      $refs.dialogDate.save(dates)
      this.showCalendar = false
    },

    sortDates(dates) {
      return dates.sort((a, b) => new Date(a) - new Date(b))
    },

    /**
     * Brazilian standard date format
     *
     * @param {string} date
     * @returns {string|undefined}
     */
    formatDate(date) {
      if (date) {
        const [year, month, day] = date.split('-')

        return `${day}/${month}/${year}`
      }

      return undefined
    },

    resetForm() {
      this.$refs.form.reset()
    },
  },
}
</script>
<style scoped>
.container-filter {
  display: flex;
  width: 100%;
  gap: 15px;
}
</style>

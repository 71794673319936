import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_c('span',[_vm._v(" Relatório COAF ")])]),_c(VCardText,[_c(VForm,{ref:"form",staticClass:"container-filter",on:{"submit":function($event){$event.preventDefault();}}},[_c(VAutocomplete,{staticStyle:{"max-width":"460px"},attrs:{"items":_vm.companyList,"item-text":"name","item-value":"id","label":"Empresa","rules":[_vm.rules.requiredArray],"clearable":"","outlined":"","multiple":"","dense":""},model:{value:(_vm.selectedCompanies),callback:function ($$v) {_vm.selectedCompanies=$$v},expression:"selectedCompanies"}}),_c(VDialog,{ref:"dialogDate",attrs:{"return-value":_vm.dates,"width":"400px","persistent":""},on:{"update:returnValue":function($event){_vm.dates=$event},"update:return-value":function($event){_vm.dates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"prepend-inner-icon":_vm.icons.mdiCalendar,"rules":[_vm.rules.requiredArrayDate],"label":"Período","clearable":"","readonly":"","outlined":"","dense":""},model:{value:(_vm.computedDate),callback:function ($$v) {_vm.computedDate=$$v},expression:"computedDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showCalendar),callback:function ($$v) {_vm.showCalendar=$$v},expression:"showCalendar"}},[_c(VDatePicker,{attrs:{"width":"400px","locale":"pt-BR","scrollable":"","range":""},on:{"dblclick":function($event){return _vm.handleMonthSelection()}},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.showCalendar = false}}},[_vm._v(" Cancelar ")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialogDate.save(_vm.dates)}}},[_vm._v(" OK ")])],1)],1),_c(VTextField,{attrs:{"label":"Valor total em dinheiro acima de","rules":[_vm.rules.required],"outlined":"","dense":""},model:{value:(_vm.cashValue),callback:function ($$v) {_vm.cashValue=$$v},expression:"cashValue"}}),_c(VBtn,{attrs:{"color":"info","outlined":""},on:{"click":function($event){return _vm.sendFormData()}}},[(!_vm.isLoading)?_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiSendCircleOutline)+" ")]):_c(VProgressCircular,{attrs:{"color":"white","indeterminate":""}})],1),_c(VBtn,{attrs:{"outlined":""},on:{"click":function($event){return _vm.resetForm()}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterRemoveOutline)+" ")]),_c('span',[_vm._v("Limpar")])],1),_c(VBtn,{attrs:{"color":"warning","disabled":_vm.exportButtonIsDisabled,"outlined":""},on:{"click":function($event){_vm.exportButtonIsDisabled = !_vm.exportButtonIsDisabled}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.icons.mdiDownloadBox)+" ")]),_c('span',[_vm._v("Exportar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }